/* custom-scrollbar.css */
/* For WebKit browsers (like Safari) */
::-webkit-scrollbar {
    width: 12px; /* Adjust the width as needed */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Change the color as needed */
    border-radius: 6px; /* Adjust the border radius as needed */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Change the hover color as needed */
  }
  
  /* For Firefox */
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
  }
  
  .scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  
  .scrollbar-thumb:hover {
    background-color: #555;
  }
  