@tailwind base;
@tailwind components;
@tailwind utilities; 

@layer components {
  .inputStyle {
    @apply w-full h-[38px] focus:outline-main focus:border-main  border-slate-300 border rounded-[5px] bg-transparent
  }
  .areaStyle {
    @apply w-full focus:outline-main focus:border-main  border-slate-300 border  rounded-[5px] bg-transparent
  }
  .card {
    @apply bg-white h-3/4 p-10 shadow rounded-xl border-2
  }

}